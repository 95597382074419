import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React from 'react'

const DownloadPage = ({rootElementId, downloadFileName}) => {
    const download =()=> {
        const input = document.getElementById(rootElementId)
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'pt', 'a4')
            pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save(`${downloadFileName}`)
        })
    }
  return (
    <div>
      <button onClick={download}>Download</button>
    </div>
  )
}

export default DownloadPage
