import React, { useContext, useEffect, useState } from 'react'
import styles from './ClothesList.module.css';
import axios from 'axios';
import { Link, Navigate } from 'react-router-dom';
import { faCheck, faPen, faPrint, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, InputGroup } from 'react-bootstrap';
import CreateList from './components/createList';
import { UserContext } from './userContext';

const ClothesList = () => {
  const [note,setNote] = useState([]);
  const [serial, setSerial] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [code, setCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [address, setAddress] = useState('');
  const [unit, setUnit] = useState('');
  const [trialPrice, setTrialPrice] = useState('');
  const [baritsaa, setBaritsaa] = useState('');
  const [cashStatus, setCashStatus] = useState('');
  const [cashBackId, setCashBackId] = useState('');
  const [isBill, setIsBill] = useState('');
  const [healt, setHealt] = useState('');
  const [healtDesc, setHealtDesc] = useState('');
  const [sector, setSector] = useState('');
  const [clothesPrepare, setClothesPrepare] = useState('');
  const [prepareOrder, setPrepareOrder] = useState('');
  const [preOrder, setPreOrder] = useState('');
  const [ordered, setOrdered] = useState('');
  const [acceptOrder, setAcceptOrder] = useState('');
  const [status, setStatus] = useState('');
  const [edit, setEdit] = useState('false');
  const [belen, setBelen] = useState('');
  const [dans, setDans] = useState('');
  const [card, setCard] = useState('');
  const [preOrderDate, setPreOrderDate] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [preOrderPrice, setPreOrderPrice] = useState('');
  const [uldegdel, setUldegdel] = useState('');
  const [whoCashBack, setWhoCashBack] = useState('');
  // const [post, setPost] = useState(false);
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState('');
  const [unitPrice, setUnitPrice] = useState(0);
  const [clothesPrice, setClothesPrice] = useState('');
  const [number, setNumber] = useState('');
  const [leng, setLeng] = useState('');
  const [searchPreOrderDate, setSearchPreOrderDate] = useState('');
  const [search, setSearch] = useState('');
  const [desc, setDesc] = useState('');
  const [clothesUnit, setClothesUnit] = useState('');
  const [preOrderDateNote, setPreOrderDateNote] = useState('');
  const [isFetch, setIsFetch] = useState(false);
  // const {userInfo} = useContext(UserContext);


  useEffect(() => {
    note.length === 0 ?
    axios
      .get("https://clothes.zyberapi.site/clotheslist?sort=-createdAt")
      .then((res) => {
        setNote(res.data.data);
        setLeng(
        note.filter((e) => {
          return e.serial.includes(serial);
         }).length);
        setTitle(res.data.data.title);
        setSerial(res.data.data.serial);
        setContactPhone(res.data.data.contactPhone);
        setAddress(res.data.data.address);
        setCode(res.data.data.clothes);
        setUnit(res.data.data.unit);
        setPreOrderDate(res.data.data.preOrderDate);
        setPreOrderPrice(res.data.data.preOrderPrice);
        setOrderDate(res.data.data.orderDate);
        setUldegdel(res.data.data.uldegdel);
        setTrialPrice(res.data.data.trialPrice);
        setBaritsaa(res.data.data.baritsaa);
        setCashStatus(res.data.data.cashStatus);
        setPrice(res.data.data.price);
        setCashBackId(res.data.data.cashBackId);
        setHealt(res.data.data.healt);
        setSector(res.data.data.sector);
        setClothesPrepare(res.data.data.clothesPrepare);
        setPrepareOrder(res.data.data.prepareOrder);
        setPreOrder(res.data.data.preOrder);
        setOrdered(res.data.data.ordered);
        setAcceptOrder(res.data.data.acceptOrder);
        setStatus(res.data.data.status);
        setDans(res.data.data.dans);
        setBelen(res.data.data.belen);
        setCard(res.data.data.card);
        setDesc(res.data.data.test_2);
        setWhoCashBack(res.data.data.test_3);
        setHealtDesc(res.data.data.test_4);
        setIsBill(res.data.data.test_5);
        setClothesUnit(res.data.data.test_8);
        setIsFetch(true);
      })
      .catch((e) => console.log(e)) : setIsFetch(true);

      // axios.get('https://clothes.zyberapi.site/post')
      // .then((res) => setClothes(res.data))
      // .catch((e) => alert(e));
  }, []);

  // if (!userInfo?.name) {
  //   return <Navigate to={'/login'} />
  // }

  async function updatePost(id) {
        axios.put('https://clothes.zyberapi.site/clotheslist/' + id, {
          title: title,
          serial: serial,
          contactPhone: contactPhone,
          address: address,
          clothes: code,
          unit: unit,
          trialPrice: trialPrice,
          baritsaa: baritsaa,
          cashStatus: cashStatus,
          belen: belen,
          dans: dans,
          preOrderDate: preOrderDate,
          orderDate: orderDate,
          uldegdel: isBill === 'true' ? "Тооцоо дууссан" : uldegdel,
          preOrderPrice: preOrderPrice,
          card: card,
          price: price,
          cashBackId: cashBackId,
          healt: healt,
          sector: sector,
          clothesPrepare: clothesPrepare,
          prepareOrder: prepareOrder,
          preOrder: preOrder,
          ordered: ordered,
          acceptOrder: acceptOrder,
          status: healt === 'true' ? 'Үйлчлүүлэгч буцаан өгсөн' : status,
          test_2: desc,
          test_3: whoCashBack,
          test_4: healtDesc,
          test_5: isBill,
          test_8: clothesUnit,
          test_6: preOrderDateNote,
        }).then(function (e) {
            window.location.reload(false);
            setEdit(true);
        }).catch(function (e) {
            alert(e);
        });
    }

  async function deletePost(id) {
    axios.delete('https://clothes.zyberapi.site/clotheslist/'+id)
    .then(function(e) {
      alert('success');
      window.location.reload(false);
    }).catch(function (e) {
      alert(e);
    })
  }

  function refreshPage() {
    window.location.reload(false);
  }

  // const newTab=()=>{
  //   window.open('https://admin.bujig.mn');
  // }

  function calculate() {
    clothesPrice * number !== 0 ?
    setUnitPrice(clothesPrice * number)
    : alert('Үнэ эсвэл хувцасны тоо ширхэг бүрэн оруулна уу');

    setClothesPrice(''); 
    setNumber('');
  }

  

  // if (redirect) {
  //   return <Navigate to={'/clotheslist'} />;
  // }

  return (
    <div className={styles.container}>
      <div className={styles.contact_column}>
        <br />
        {/* <p>{note.filter((e) => {
          return e.serial && e.serial.includes(serial);
         }).length}</p> */}
        <div className={styles.unitprice}>
          <input 
            type="text" 
            placeholder='хувцасны үнэ'
            value={clothesPrice}
            onChange={(e) => setClothesPrice(e.target.value)}
          />
          <input 
            type="text"
            placeholder='хувцасны тоо ширхэг'
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          {unitPrice === 0 ? <p>Нийт үнэ: {unitPrice}</p> : <p className={styles.done}>Нийт үнэ: {unitPrice}</p> }
          <button className={styles.calculate} onClick={(e) => calculate()}>Calculate</button>
        </div>

        <br />
        {/* <Form>
          <InputGroup>
            <Form.Control placeholder="Хайх хувцасны кодоо бичнэ үү" onChange={(e) => setClothesTitle(e.target.value)}/>
          </InputGroup>
        </Form>
       
        { clothes.filter((e) => {
          return clothesTitle.toLowerCase() === '' ? e : e.title && e.title.toLowerCase().includes(clothesTitle);
        }).length < clothes.length ? clothes.filter((e) => {
          return clothesTitle.toLowerCase() === '' ? e : e.title && e.title.toLowerCase().includes(clothesTitle);
        }).map((e) => 
          <div className={styles.contact_row}>
            <p>Код: {e.title}</p>
            <p>Үнэ: {e.price}</p>
            <p>Нийт үлдэгдэл: {e.content}</p>
            <p>Одоогийн үлдэгдэл: {parseInt(e.content) - note.filter((ev) => { 
              return ev.clothes && ev.clothes.includes(e.title) && (
                ev.status.toLowerCase().includes('захиалгатай') 
                || ev.status.toLowerCase().includes('буцаж ирээгүй') 
                || ev.status.toLowerCase().includes('авсан') 
                || ev.status.toLowerCase().includes('зарагдсан') 
              )}).length}ш</p>
            <img src={'https://clothes.zyberapi.site/' + e.cover} className={styles.img} alt="" />
          </div>
        ) : <div></div> } */}
        <br />
          <Form>
            <InputGroup>
              <Form.Control placeholder="Хайх өдрийн он сарыг бичнэ үү" onChange={(e) => setLeng(e.target.value)}/>
            </InputGroup>
          </Form>
          <p>Таны хайсан өдөрт бүртгэл хийсэн хувцасны тоо: {leng.length > 0 ? note.filter((e) => {
              return e.serial && e.serial.includes(leng);
        }).length : ''}</p>
        <p>
        {note.filter((e) => {
              return leng === '' ? '' : (e.serial && e.serial.includes(leng));
        }).map((e) => (
          <p>Нийт түрээс үнэ: {e.trialPrice} -- он сар: {e.serial}</p>
        ))}
        </p>
        <br />
          <Form>
            <InputGroup>
              <Form.Control placeholder="Захиалсан өдрийн он сараар хайх" onChange={(e) => setSearchPreOrderDate(e.target.value)}/>
            </InputGroup>
          </Form>
          <p>Таны хайсан өдөрт захиалга хийсэн хувцасны тоо: {searchPreOrderDate.length > 0 ? note.filter((e) => {
              return e.preOrderDate && e.preOrderDate.includes(searchPreOrderDate);
        }).length : ''}</p>
        <p>
        {note.filter((e) => {
              return searchPreOrderDate === '' ? '' : (e.preOrderDate && e.preOrderDate.includes(searchPreOrderDate) && e.uldegdel != 'Тооцоо дууссан');
        }).map((e) => (
          <p>Нийт түрээс үнэ: {e.trialPrice} ---- он сар: {e.preOrderDate} ---- 
          Үлдэгдэл: {e.uldegdel} ---- Холбоо барих утас {e.contactPhone} ---- 
          хувцасны код: {e.clothes} ---- {e.status}</p>
        ))}
        </p>
        <br />
        <Form>
          <InputGroup>
            <Form.Control placeholder="Хайх утасны дугаараа бичнэ үү" onChange={(e) => setFilter(e.target.value)}/>
          </InputGroup>
        </Form>
        <br />
        <Form>
          <InputGroup>
            <Form.Control placeholder="Бүртгэлээс хайх хувцасны кодыг бичнэ үү" onChange={(e) => setSearch(e.target.value)}/>
          </InputGroup>
        </Form>
        <br />
        <CreateList />

      {/* <div className={styles.contact_row} >
        <button onClick={refreshPage}>Click to reload!</button> 
        { post === true ?
         <CreateList /> : <div></div> } 
        </div> */}
        {filterType.length > 0 || search.length > 0 ? <h3>Статус: {filterType}</h3> : <h2></h2>}
        {note.filter((e) => {
          return filter.toLowerCase() === '' ? e : e.contactPhone && e.contactPhone.toLowerCase().includes(filter);
        }).filter((e) => {
          return filterType.toLowerCase() === '' ? e : e.status.includes(filterType);
        }).filter((e) => {
          return search.toLowerCase() === '' ? e : (e.clothes && e.clothes.includes(search));
        }).length > 0 ? note.filter((e) => {
          return filter.toLowerCase() === '' ? e : e.contactPhone && e.contactPhone.toLowerCase().includes(filter);
        }).filter((e) => {
            return filterType.toLowerCase() === '' ? e : e.status.includes(filterType);
        }).filter((e) => {
          return search.toLowerCase() === '' ? e : (e.clothes && e.clothes.includes(search));
        }).map((e) => 
          <div className={styles.contact_row}>
            <h2>Хэрэглэгчийн бүртгэл</h2>
            <Link className={styles.link} to={'/pdf/' + e._id}>Pdf download <FontAwesomeIcon icon={faPrint} className={styles.icon} /></Link>
            {/* <p className={styles.link} to={'/pdf/' + e._id}>
              Бүртгэл устгах 
              <FontAwesomeIcon onClick={() => deletePost(e._id)} icon={faTrash} className={styles.icon} />
            </p> */}
            <div>
              <p>Дугаар код: <br /> <br /> {e.serial}</p>
              {/* <p>Дугаар код: <br /> <br />{new Date(e.createdAt).getUTCHours()}</p> */}
              <p>Нэр: <br /> <br /> {e.title}</p>
              <p>Утасны Дугаар: <br /> <br /> {e.contactPhone}</p>
              <p>Хэрэглэгчийн хаяг: <br /> <br /> {e.address}</p>
              { edit === 'true' ?
                <div className={styles.edit}>
                  <p>Барьцаа буцаан өгөх данс:</p>
                  <input
                    type="text"
                    placeholder='Барьцаа буцаан өгөх данс'
                    value={cashBackId}
                    onChange={(ev) => setCashBackId(ev.target.value)}
                  />
                </div>
                : <p>Барьцаа буцаан өгөх данс: <br /> <br /> {e.cashBackId}</p>
              }
              { edit === 'true' ?
                <div className={styles.edit}>
                  <p>Барьцаа буцаан өгсөн хүн:</p>
                  <input
                    type="text"
                    placeholder='Барьцаа буцаан өгсөн хүн'
                    value={whoCashBack}
                    onChange={(ev) => setWhoCashBack(ev.target.value)}
                  />
                </div>
                : <p>Барьцаа буцаан өгсөн хүн: <br /> <br /> {e.test_3}</p>
              }
              {/* { edit === 'true' ?
                <div className={styles.edit}>
                  <p>Захиалсан хувцас өгөх өдөр:</p>
                  <input
                    type="text"
                    placeholder='Захиалсан хувцас өгөх өдөр'
                    value={preOrderDate}
                    onChange={(ev) => setPreOrderDate(ev.target.value)}
                  />
                </div>
                : <p>Захиалсан хувцас өгөх өдөр: <br /> <br /> {e.preOrderDate}</p>
              } */}
              { edit === 'true' ?
                <div className={styles.edit}>
                  <p>Захиалсан хувцас өгөх өдөр:</p>
                  <input
                    type="text"
                    placeholder='Захиалсан хувцас өгөх өдөр'
                    value={preOrderDate}
                    onChange={(ev) => setPreOrderDate(ev.target.value)}
                  />
                </div>
                : <p>Захиалсан хувцас өгөх өдөр: <br /> <br /> {e.preOrderDate}</p>
              }
            </div>
            <div>
            <p>Тэмдэглэл: <br /> <br /> {e.test_2}</p>
            { edit === 'true' ?
                <div className={styles.edit}>
                  <p>Захиалсан өдөр солисон тэмдэглэл:</p>
                  <input
                    type="text"
                    placeholder='Захиалсан өдөр солисон тэмдэглэл'
                    value={preOrderDateNote}
                    onChange={(ev) => setPreOrderDateNote(ev.target.value)}
                  />
                </div>
                : <p>Захиалсан өдөр солисон тэмдэглэл: <br /> <br /> {e.test_6}</p>
              }
            </div>
            <div>
              <p>Хувцас код: <br /> <br /> {e.clothes}</p>
              <p>Хувцас тоо ширхэг: <br /> <br /> {e.unit}</p>
              <p>Хувцас тоо ширхэг (код): <br /> <br /> {e.test_8}</p>
              <p>Хувцас буцаан өгөх өдөр: <br /> <br /> {e.orderDate}</p>
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Хувцас бүрэн өгсөн эсэх:</p>
                  <select onChange={(e) => setHealt(e.target.value)}>
                    <option value=""> </option>
                    <option value="true">Тийм</option>
                    <option value="false">Үгүй</option>
                  </select>
                  
                </div>
              : <p>Хувцас бүрэн өгсөн эсэх: <br /> <br /> 
                {e.healt === 'true' ? 'Тийм' : 'Үгүй'}
              <br /> <br />
                    {/* <FontAwesomeIcon onClick={() => (setEdit('healt'))} icon={faPen} className={styles.icon} />  */}
                    </p>
              }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Буцаж ирээгүй бол тэмдэглэл:</p>
                  <input
                    type="text"
                    placeholder='Буцаж ирээгүй бол тэмдэглэл:'
                    value={healtDesc}
                    onChange={(ev) => setHealtDesc(ev.target.value)}
                  />
                  
                </div>
              : <p>Буцаж ирээгүй бол тэмдэглэл: <br /> <br /> {e.test_4}<br /> <br />
                    {/* <FontAwesomeIcon onClick={() => (setEdit('healtDesc'))} icon={faPen} className={styles.icon} />  */}
                    </p>
              }
            </div>
            <div>
              <p>Түрээс үнэ: <br /> <br /> {e.trialPrice}</p>
              <p>Барьцаа үнэ: <br /> <br /> {e.baritsaa}</p>
              {/* <p>Мөнгө Статус: <br /> <br /> {e.cashStatus}</p> */}
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Бэлэн:</p>
                  <input
                    type="text"
                    placeholder='Бэлэн'
                    value={belen}
                    onChange={(ev) => setBelen(ev.target.value)}
                  />
                  
                </div> 
                : <p>Бэлэн: <br /> <br /> {e.belen}<br /> <br />
                    </p>
              }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Данс:</p>
                  <input
                    type="text"
                    placeholder='Данс'
                    value={dans}
                    onChange={(ev) => setDans(ev.target.value)}
                  />
                  
                </div> 
                : <p>Данс: <br /> <br /> {e.dans}<br /> <br />
                    </p>
              }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Карт:</p>
                  <input
                    type="text"
                    placeholder='Карт'
                    value={card}
                    onChange={(ev) => setCard(ev.target.value)}
                  />
                  
                </div> 
                : <p>Карт: <br /> <br /> {e.card}<br /> <br />
                    </p>
              }
              {/* <p>Бэлэн: <br /> <br /> {e.belen}</p>
              <p>Данс: <br /> <br /> {e.dans}</p>
              <p>Карт: <br /> <br /> {e.card}</p> */}
              <p>Нийт үнэ: <br /> <br /> {e.price}</p>
              <p>Захиалгын урьдчилгаа: <br /> <br /> {e.preOrderPrice}</p>
            </div>
            <div>
            <p className={styles.uldegdel}>Өгөх үлдэгдэл: <br /> <br /> {e.uldegdel}</p>
            { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Төлбөр бүрэн өгсөн эсэх:</p>
                  {/* <select onChange={(e) => setIsBill(e.target.value)}>
                    <option value=""> </option>
                    <option value="true">Тийм</option>
                    <option value="false">Үгүй</option>
                  </select> */}
                  <div>
                    { isBill === 'true' ? <p className={styles.status_button_active} onClick={() => setIsBill('true')}>Тйим</p> : <p className={styles.status_button} onClick={() => setIsBill('true')}>Тйим</p> }
                    { isBill === 'false' ? <p className={styles.status_button_active} onClick={() => setIsBill('false')}>Үгүй</p> : <p className={styles.status_button} onClick={() => setIsBill('false')}>Үгүй</p> }
                  </div>
                  
                </div>
              : <p>Төлбөр бүрэн өгсөн эсэх: <br /> <br /> 
                  {e.test_5 === 'true' ? 'Тийм' : 'Үгүй'}
                  <br /> <br />
                  {/* <FontAwesomeIcon onClick={() => (setEdit('isBill'))} icon={faPen} className={styles.icon} />  */}
                  </p>
              }
            </div>
            <div>
              <p>Салбар: <br /> <br /> {e.sector}</p>
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Хувцас бэлдсэн эсэх:</p>
                  <input
                    type="text"
                    placeholder='Хувцас бэлдсэн'
                    value={clothesPrepare}
                    onChange={(ev) => setClothesPrepare(ev.target.value)}
                  />
                </div> 
                : <p>Хувцас бэлдсэн эсэх: <br /> <br /> {e.clothesPrepare}<br /> <br />
                    </p>
              }
              { edit === 'true'
              ? <div className={styles.edit}>
                  <p>Захиалга бэлдсэн хүн:</p>
                  <input
                    type="text"
                    placeholder='Захиалга бэлдсэн хүн'
                    value={prepareOrder}
                    onChange={(ev) => setPrepareOrder(ev.target.value)}
                  />
                </div> 
                : <p>Захиалга бэлдсэн хүн: <br /> <br /> {e.prepareOrder}<br /> <br />
                    </p>
                }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Захиалга авсан хүн:</p>
                  <input
                    type="text"
                    placeholder="Захиалга авсан хүн"
                    value={preOrder}
                    onChange={(ev) => setPreOrder(ev.target.value)}
                  />
                </div> 
               : <p>Захиалга авсан хүн: <br /> <br /> {e.preOrder} <br /> <br />
                  {/* <FontAwesomeIcon onClick={() => (setEdit('preOrder'))} icon={faPen} className={styles.icon} /> */}
                </p> 

              }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Захиалга өгсөн хүн:</p>
                  <input
                    type="text"
                    placeholder="Захиалга өгсөн хүн"
                    value={ordered}
                    onChange={(ev) => setOrdered(ev.target.value)}
                  />
                </div>
                : <p>Захиалга өгсөн хүн: <br /> <br /> {e.ordered}<br /> <br />
                    
                    </p>

              }
              { edit === 'true' 
              ? <div className={styles.edit}>
                  <p>Хүлээж авсан хүн:</p>
                  <input
                    type="text"
                    placeholder="Хүлээж авсан хүн"
                    value={acceptOrder}
                    onChange={(ev) => setAcceptOrder(ev.target.value)}
                  />
                </div> 
                : <p>Хүлээж авсан хүн: <br /> <br /> {e.acceptOrder}<br /> <br />
                    </p>
              }
              </div>
              <div className={styles.contact_row_special}>
                 { edit === 'true' ?
                   <div>
                   <p>Статус: {e.status}</p>
                     <div className={styles.status}>
                     { status === 'Үйлчлүүлэгч авсан' ? <p className={styles.status_button_active} onClick={() => setStatus('Үйлчлүүлэгч авсан')}>Үйлчлүүлэгч авсан</p> : <p className={styles.status_button} onClick={() => setStatus('Үйлчлүүлэгч авсан')}>Үйлчлүүлэгч авсан</p> }
                     { status === 'Захиалгатай' ? <p className={styles.status_button_active} onClick={() => setStatus('Захиалгатай')}>Захиалгатай</p> : <p className={styles.status_button} onClick={() => setStatus('Захиалгатай')}>Захиалгатай</p> }
                     { status === 'Буцаж ирээгүй' ? <p className={styles.status_button_active} onClick={() => setStatus('Буцаж ирээгүй')}>Буцаж ирээгүй</p> : <p className={styles.status_button} onClick={() => setStatus('Буцаж ирээгүй')}>Буцаж ирээгүй</p> }
                     { status === 'Үйлчлүүлэгч буцаан өгсөн' ? <p className={styles.status_button_active} onClick={() => setStatus('Үйлчлүүлэгч буцаан өгсөн')}>Үйлчлүүлэгч буцаан өгсөн</p> : <p className={styles.status_button} onClick={() => setStatus('Үйлчлүүлэгч буцаан өгсөн')}>Үйлчлүүлэгч буцаан өгсөн</p> }
                     { status === 'Зарагдсан' ? <p className={styles.status_button_active} onClick={() => setStatus('Зарагдсан')}>Зарагдсан</p> : <p className={styles.status_button} onClick={() => setStatus('Зарагдсан')}>Зарагдсан</p> }
                   </div>
                 </div>
                 : <p className={styles.status} >Статус: <br />{e.status}<br /> <br />
                     </p>
                }
                { edit === 'false' ? <button className={styles.editButton} onClick={() => setEdit('true')}>Өөрчлөлт хийх</button> 
                : <div className={styles.special_column}>
                    <button className={styles.createButton} onClick={() => (updatePost(e._id))}>
                      Өөрчлөлтийг хадгалах
                      <FontAwesomeIcon icon={faCheck} className={styles.icon} />
                    </button>
                    <button className={styles.deleteButton} onClick={() => (setEdit('false'))}>
                      Бүх өөрчлөлтийг цуцлах
                      <FontAwesomeIcon icon={faXmark} className={styles.icon} /> 
                    </button>
                  </div> }
            </div>
          </div>
        ) : 
          <div className={styles.empty}>
            <h2>хоосон байна</h2>
          </div>
          }
        </div>
      <div className={styles.button_container}>
        <button onClick={() => setFilterType('')}>Бүгд</button>
        <button onClick={() => setFilterType('Үйлчлүүлэгч авсан')}>Үйлчлүүлэгч авсан</button>
        <button onClick={() => setFilterType('Захиалгатай')}>Захиалгатай</button>
        <button onClick={() => setFilterType('Буцаж ирээгүй')}>Буцаж ирээгүй</button>
        <button onClick={() => setFilterType('Үйлчлүүлэгч буцаан өгсөн')}>Үйлчлүүлэгч буцаан өгсөн</button>
        <button onClick={() => setFilterType('Зарагдсан')}>Зарагдсан</button>
        <button onClick={() => setFilterType('Авсан')}>Салбар 1</button>
        <button onClick={() => setFilterType('Авсан')}>Салбар 2</button>
        <button onClick={() => setFilterType('Авсан')}>Салбар 3</button>
        {/* <button onClick={() => newTab()} className={styles.createButton} >Хувцас хайх</button> */}
        {/* <Link onClick={() => setPost(true)} className={styles.createButton}>Create Clothes List</Link> */}
      </div>
    </div>
  )
}

export default ClothesList
