import React, { useEffect, useState } from 'react'
import DownloadPage from './download'
import styles from './pdfPage.module.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'

const PdfPage = () => {
  const [note, setNote] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios.get('https://clothes.zyberapi.site/clotheslist/'+id)
    .then((e) => {
      setNote(e.data.data);
    })
    .catch((e) => alert(e))
  })
  return (
    <div className={styles.container} id='pagetodownload'>
      {/* {id} */}
      <div>
        <p>Дугаар код: {note.serial}</p>
        {/* <p>Дугаар код:{new Date(note.createdAt).getUTCHours()}</p> */}
        <p>Нэр: {note.title}</p>
        <p>Утасны Дугаар: {note.contactPhone}</p>
        <p>Хэрэглэгчийн хаяг: {note.address}</p>
        <p>Барьцаа буцаан өгөх данс: {note.cashBackId}</p>
        <p>Захиалсан хувцас өгөх өдөр: {note.preOrderDate}</p>
      </div>
      <div>
          <p>Хувцас код: {note.clothes}</p>
          <p>Хувцас тоо ширхэг: {note.unit}</p>
          <p>Хувцас буцаан өгөх өдөр: {note.orderDate}</p>
      </div>
      <div>
        <p>Хувцас бүрэн өгсөн эсэх: {note.healt}</p>
        <p>Түрээс үнэ: {note.trialPrice}</p>
        <p>Барьцаа үнэ: {note.baritsaa}</p>
        {/* <p>Мөнгө Статус: {note.cashStatus}</p> */}
        <p>Бэлэн: {note.belen}</p>
        <p>Данс: {note.dans}</p>
        <p>Карт: {note.card}</p>
        <p>Нийт үнэ: {note.price}</p>
        <p>Захиалгын урьдчилгаа: {note.preOrderPrice}</p>
        <p>Өгөх үлдэгдэл: {note.uldegdel}</p>
      </div>
      <div>
        <p>Салбар: {note.sector}</p>
        <p>Хувцас бэлдсэн эсэх: {note.clothesPrepare}</p>
        <p>Захиалга бэлдсэн хүн: {note.prepareOrder}</p>
        <p>Захиалга авсан хүн: {note.preOrder}</p>
        <p>Захиалга өгсөн хүн: {note.ordered}</p>
        <p>Хүлээж авсан хүн: {note.acceptOrder}</p>
        <p>Статус: {note.status}</p>
      </div>
      <DownloadPage rootElementId={'pagetodownload'} downloadFileName={`${note.contactPhone}`} />
    </div>
  )
}

export default PdfPage
