import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Make sure to import 'BrowserRouter' or other router type.

import ClothesList from './ClothesList';
import PdfPage from './components/PdfPage';
import { UserContextProvider } from './userContext';
import LoginPage from './Page/LoginPage';

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<ClothesList />} />
          <Route path="/pdf/:id" element={<PdfPage />} />
        </Routes>
      </Router>
    </UserContextProvider>
  );
}

export default App;
