import React, { useEffect, useState } from 'react'
import styles from './CreateList.module.css';
import axios from 'axios';
import { faCheck, faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const CreateList = () => {
  const [isFetch,setIsFetch] = useState(false);
  const [clothes,setClothes] = useState([]);
  const [note, setNote] = useState([]);
  const [allPrice, setAllPrice] = useState(0);
  const [allBaritsaa, setAllBaritaa] = useState(0);
  const [allTrialPrice, setAllTrialPrice] = useState(0);
  const [editAllPrice, setEditAllPrice] = useState('0');
  const [editAllBaritsaa, setEditAllBaritaa] = useState('0');
  const [editAllTrialPrice, setEditAllTrialPrice] = useState('0');
  const [acceptEdit, setAcceptEdit] = useState(false);
  const [serial, setSerial] = useState('');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [code, setCode] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [address, setAddress] = useState('');
  const [unit, setUnit] = useState('');
  const [cashBackId, setCashBackId] = useState('');
  const [healt, setHealt] = useState('');
  const [sector, setSector] = useState('');
  const [clothesPrepare, setClothesPrepare] = useState('');
  const [prepareOrder, setPrepareOrder] = useState('');
  const [preOrder, setPreOrder] = useState('');
  const [ordered, setOrdered] = useState('');
  const [acceptOrder, setAcceptOrder] = useState('');
  const [status, setStatus] = useState('');
  const [belen, setBelen] = useState('');
  const [dans, setDans] = useState('');
  const [card, setCard] = useState('');
  const [preOrderDate, setPreOrderDate] = useState('');
  const [backDate, setBackDate] = useState('');
  const [preOrderPrice, setPreOrderPrice] = useState(0);
  const [uldegdel, setUldegdel] = useState('');
  const [mainUldegdel, setMainUldegdel] = useState('');
  const [post, setPost] = useState(false);
  const [post_2, setPost_2] = useState(false);
  const [post_3, setPost_3] = useState(false);
  const [post_4, setPost_4] = useState(false);
  const [post_5, setPost_5] = useState(false);
  const [post_6, setPost_6] = useState(false);
  const [post_7, setPost_7] = useState(false);
  const [post_8, setPost_8] = useState(false);
  const [post_9, setPost_9] = useState(false);
  const [post_10, setPost_10] = useState(false);
  const [code_1, setCode_1] = useState('');
  const [code_2, setCode_2] = useState('');
  const [code_3, setCode_3] = useState('');
  const [code_4, setCode_4] = useState('');
  const [code_5, setCode_5] = useState('');
  const [code_6, setCode_6] = useState('');
  const [code_7, setCode_7] = useState('');
  const [code_8, setCode_8] = useState('');
  const [code_9, setCode_9] = useState('');
  const [code_10, setCode_10] = useState('');
  const [trialPrice_1, setTrialPrice_1] = useState(0);
  const [trialPrice_2, setTrialPrice_2] = useState(0);
  const [trialPrice_3, setTrialPrice_3] = useState(0);
  const [trialPrice_4, setTrialPrice_4] = useState(0);
  const [trialPrice_5, setTrialPrice_5] = useState(0);
  const [trialPrice_6, setTrialPrice_6] = useState(0);
  const [trialPrice_7, setTrialPrice_7] = useState(0);
  const [trialPrice_8, setTrialPrice_8] = useState(0);
  const [trialPrice_9, setTrialPrice_9] = useState(0);
  const [trialPrice_10, setTrialPrice_10] = useState(0);
  const [baritsaa_1, setBaritsaa_1] = useState(0);
  const [baritsaa_2, setBaritsaa_2] = useState(0);
  const [baritsaa_3, setBaritsaa_3] = useState(0);
  const [baritsaa_4, setBaritsaa_4] = useState(0);
  const [baritsaa_5, setBaritsaa_5] = useState(0);
  const [baritsaa_6, setBaritsaa_6] = useState(0);
  const [baritsaa_7, setBaritsaa_7] = useState(0);
  const [baritsaa_8, setBaritsaa_8] = useState(0);
  const [baritsaa_9, setBaritsaa_9] = useState(0);
  const [baritsaa_10, setBaritsaa_10] = useState(0);
  const [unit_1, setUnit_1] = useState(0);
  const [unit_2, setUnit_2] = useState(0);
  const [unit_3, setUnit_3] = useState(0);
  const [unit_4, setUnit_4] = useState(0);
  const [unit_5, setUnit_5] = useState(0);
  const [unit_6, setUnit_6] = useState(0);
  const [unit_7, setUnit_7] = useState(0);
  const [unit_8, setUnit_8] = useState(0);
  const [unit_9, setUnit_9] = useState(0);
  const [unit_10, setUnit_10] = useState(0);
  const [clothesUnit, setClothesUnit] = useState('');
  const [photo, setPhoto] = useState('');
  const [clothesCode, setClothesCode] = useState('');
  

  useEffect(() => {
    note.length === 0 ? axios
      .get("https://clothes.zyberapi.site/clotheslist?sort=-createdAt")
      .then((res) => {
        setNote(res.data.data);
      })
      .catch((e) => console.log(e)) : setIsFetch(true);

      clothes.length === 0 ? axios.get('https://clothes.zyberapi.site/post')
      .then((res) => {
        setClothes(res.data);
        setIsFetch(true);
      })
      .catch((e) => alert(e)) : setIsFetch(true);

      clothes.filter((e) => {
        // return (
        //   code_1.toLowerCase() === '' ?
        //   (e.search && e.search.toLowerCase().includes('z' +  code_1 + 'z'))
        // );
        return code_1.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_1 + 'z');

      }).map((e) => (            
        setTrialPrice_1(e.price),
        setBaritsaa_1(e.description)
        // setPhoto(e.cover),
        // setClothesCode(e.title)
      ));
      
      clothes.filter((e) => {
        return code_2.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_2 + 'z');
      }).map((e) => (            
        setTrialPrice_2(e.price),
        setBaritsaa_2(e.description)
      ));
      clothes.filter((e) => {
        return code_3.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_3 + 'z');
      }).map((e) => (            
        setTrialPrice_3(e.price),
        setBaritsaa_3(e.description)
      ));
      clothes.filter((e) => {
        return code_4.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_4 + 'z');
      }).map((e) => (            
        setTrialPrice_4(e.price),
        setBaritsaa_4(e.description)
      ));
      clothes.filter((e) => {
        return code_5.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_5 + 'z');
      }).map((e) => (            
        setTrialPrice_5(e.price),
        setBaritsaa_5(e.description)
      ));
      clothes.filter((e) => {
        return code_6.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_6 + 'z');
      }).map((e) => (            
        setTrialPrice_6(e.price),
        setBaritsaa_6(e.description)
      ));
      clothes.filter((e) => {
        return code_7.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_7 + 'z');
      }).map((e) => (            
        setTrialPrice_7(e.price),
        setBaritsaa_7(e.description)
      ));
      clothes.filter((e) => {
        return code_8.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_8 + 'z');
      }).map((e) => (            
        setTrialPrice_8(e.price),
        setBaritsaa_8(e.description)
      ));
      clothes.filter((e) => {
        return code_9.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_9 + 'z');
      }).map((e) => (            
        setTrialPrice_9(e.price),
        setBaritsaa_9(e.description)
      ));
      clothes.filter((e) => {
        return code_10.toLowerCase() === '' ? '' : e.search && e.search.toLowerCase().includes('z' + code_10 + 'z');
      }).map((e) => (            
        setTrialPrice_10(e.price),
        setBaritsaa_10(e.description)
      ));

      if (code_1 === '' && baritsaa_1 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_1(0);
        setTrialPrice_1(0);
      } 

      if (code_2 === '' && baritsaa_2 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_2(0);
        setTrialPrice_2(0);
      } 

      if (code_3 === '' && baritsaa_3 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_3(0);
        setTrialPrice_3(0);
      } 

      if (code_4 === '' && baritsaa_4 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_4(0);
        setTrialPrice_4(0);
      } 

      if (code_5 === '' && baritsaa_5 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_5(0);
        setTrialPrice_5(0);
      } 

      if (code_6 === '' && baritsaa_6 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_6(0);
        setTrialPrice_6(0);
      } 

      if (code_7 === '' && baritsaa_7 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_7(0);
        setTrialPrice_7(0);
      } 

      if (code_8 === '' && baritsaa_8 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_8(0);
        setTrialPrice_8(0);
      } 

      if (code_9 === '' && baritsaa_9 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_9(0);
        setTrialPrice_9(0);
      } 

      if (code_10 === '' && baritsaa_10 !== 0) {
        setAllBaritaa(0);
        setAllTrialPrice(0);
        setAllPrice(0);
        setBaritsaa_10(0);
        setTrialPrice_10(0);
      } 



        setClothesUnit(code_1 + ' ' + unit_1 + 'ш ' + code_2 + ' ' + unit_2 + 'ш ' + code_3 + ' ' + unit_3 + 'ш ' +
          code_4 + ' ' + unit_4 + 'ш ' + code_5 + ' ' + unit_5 + 'ш ' + code_6 + ' ' + unit_6 + 'ш ' + code_7 + ' ' + unit_7 + 'ш ' + 
          code_8 + ' ' + unit_8 + 'ш ' + code_9 + ' ' + unit_9 + 'ш ' + code_10 + ' ' + unit_10 + 'ш ');

      const intervalId = setInterval(() => {
        //  axios.get('https://clothes.zyberapi.site/post')
        // .then((res) => setClothes(res.data))
        // .catch((e) => alert(e));

        setCode(
          code_1 + ' ' + code_2 + ' ' + code_3 + ' ' + code_4 + ' ' +
          code_5 + ' ' + code_6 + ' ' + code_7 + ' ' + code_8 + ' ' + code_9 + ' ' + code_10
        );

        setUnit(
          parseInt(unit_1) + parseInt(unit_2) + parseInt(unit_3) + parseInt(unit_4) + parseInt(unit_5) +
          parseInt(unit_6) + parseInt(unit_7) + parseInt(unit_8) + parseInt(unit_9) + parseInt(unit_10)
        );
        
        setAllBaritaa(
          (parseInt(baritsaa_1) * parseInt(unit_1)) + (parseInt(baritsaa_2) * parseInt(unit_2)) 
          + (parseInt(baritsaa_3) * parseInt(unit_3)) + (parseInt(baritsaa_4) * parseInt(unit_4)) 
          + (parseInt(baritsaa_5) * parseInt(unit_5)) + (parseInt(baritsaa_6) * parseInt(unit_6))
          + (parseInt(baritsaa_7) * parseInt(unit_7)) + (parseInt(baritsaa_8) * parseInt(unit_8)) 
          + (parseInt(baritsaa_9) * parseInt(unit_9)) + (parseInt(baritsaa_10) * parseInt(unit_10)));

        setAllTrialPrice(
          (parseInt(trialPrice_1) * parseInt(unit_1)) + (parseInt(trialPrice_2) * parseInt(unit_2)) 
          + (parseInt(trialPrice_3) * parseInt(unit_3)) + (parseInt(trialPrice_4) * parseInt(unit_4)) 
          + (parseInt(trialPrice_5) * parseInt(unit_5)) + (parseInt(trialPrice_6) * parseInt(unit_6)) 
          + (parseInt(trialPrice_7) * parseInt(unit_7)) + (parseInt(trialPrice_8) * parseInt(unit_8)) 
          + (parseInt(trialPrice_9) * parseInt(unit_9)) + (parseInt(trialPrice_10) * parseInt(unit_10)));

        setAllPrice((parseInt(allBaritsaa) / (unit > 3 ? 2 : 1)) + parseInt(allTrialPrice));

        setUldegdel((acceptEdit === true ? parseInt(editAllPrice) : parseInt(allPrice)) - parseInt(preOrderPrice));

        setEditAllPrice(parseInt(editAllBaritsaa) + parseInt(editAllTrialPrice));
      }, 100);

      return () => {
        clearInterval(intervalId);
      };
  }, [editAllPrice, acceptEdit, editAllTrialPrice, editAllBaritsaa, preOrderPrice, clothes, allPrice, allBaritsaa, allTrialPrice, code_1, code_2, code_3, code_4,
    code_5, code_6, code_7, code_8, code_9, code_10, 
    baritsaa_1, baritsaa_2, baritsaa_3, baritsaa_4, baritsaa_5, baritsaa_6, 
    baritsaa_7, baritsaa_8, baritsaa_8, baritsaa_10,
    trialPrice_1, trialPrice_2, trialPrice_3, trialPrice_4, trialPrice_6, trialPrice_6
    , trialPrice_7, trialPrice_8, trialPrice_9, trialPrice_10, unit_1, unit_2, unit_3, unit_4, 
    unit_5, unit_6, unit_7, unit_8, unit_9, unit_10,]);

  async function createNewPost(ev) {
      ev.preventDefault();    
      let a = note.filter((e) => {
        return e.serial && e.serial.includes(serial);
       }).length + 1;

       let baritsaaResult = parseInt(allBaritsaa) / (unit > 3 ? 2 : 1);
      //  let editbaritsaaResult = parseInt(editAllBaritsaa) / (unit > 3 ? 2 : 1);
       await axios.post('https://clothes.zyberapi.site/clotheslist', {
           title: title,
           serial: serial + '-' + a,
           contactPhone: contactPhone,
           address: address,
           clothes: code,
           unit: unit,
           trialPrice: acceptEdit === false ? allTrialPrice  : editAllTrialPrice ,
           baritsaa:  acceptEdit === false ? baritsaaResult  : editAllBaritsaa,
        //    cashStatus: cashStatus,
           belen: belen,
           dans: dans,
           card: card,
           price: acceptEdit === false ? allPrice  : editAllPrice,
           cashBackId: cashBackId,
           healt: healt,
           sector: sector,
           clothesPrepare: clothesPrepare,
           prepareOrder: prepareOrder,
           preOrder: preOrder,
           ordered: ordered,
           acceptOrder: acceptOrder,
           status: status,
           preOrderDate: preOrderDate,
           orderDate: backDate,
           preOrderPrice: preOrderPrice,
           uldegdel: uldegdel,
           test_2: desc,
           test_8: clothesUnit,
       }).then(function (e) {
           window.location.reload(false);
           setPost(false);
          //  alert('true');
       }).catch(function (e) {
           alert(e);
       });
  }
  function refreshPage() {
    window.location.reload(false);
  }

  return (
   <div>
    { post === true ? clothes.length > 0 ?
    <form className={styles.contact_column} onSubmit={createNewPost}>
      <h2>Бүртгэл</h2>
      {/* <p>{clothesUnit}</p> */}
        <div className={styles.contact_row_create}>
            <div>
                <p>Цуврал дугаар</p>
                <input
                    type="text"
                    placeholder="Цуврал дугаар"
                    value={serial}
                    onChange={(ev) => setSerial(ev.target.value)}
                />
            </div>
            <div>
                <p>Нэр Байгуулга</p>
                <input
                  type="text"
                  placeholder="Нэр Байгуулга"
                  value={title}
                  onChange={(ev) => setTitle(ev.target.value)}
                />
            </div>
            <div>
                <p>Утасны дугаар</p>
                <input
                  type="text"
                  placeholder="Утасны дугаар"
                  value={contactPhone}
                  onChange={(ev) => setContactPhone(ev.target.value)}
                />
            </div>
            <div>
                <p>Хэрэглэгчийн хаяг</p>
                <input
                  type="text"
                  placeholder="Хэрэглэгчийн хаяг"
                  value={address}
                  onChange={(ev) => setAddress(ev.target.value)}
                />
            </div>
            <div>
              <p>Барьцаа буцаан өгөх данс</p>
              <input
                type="text"
                placeholder="Барьцаа буцаан өгөх данс"
                value={cashBackId}
                onChange={(ev) => setCashBackId(ev.target.value)}
              />
            </div>
            <div>
                <p>Захиалга авах он сар</p>
                <input
                  type="text"
                  placeholder="Захиалга авах он сар"
                  value={preOrderDate}
                  onChange={(ev) => setPreOrderDate(ev.target.value)}
                />
            </div>
            <div>
                <p>Буцаан авчрах он сар</p>
                <input
                  type="text"
                  placeholder="Буцаан авчрах он сар"
                  value={backDate}
                  onChange={(ev) => setBackDate(ev.target.value)}
                />
            </div>
        </div>
        <div className={styles.contact_row_create}>
          <p className={styles.select_title}>Статус: </p>
          { status === 'Авсан' ? <p className={styles.status_button_active} onClick={() => setStatus('Авсан')}>Авсан</p> : <p className={styles.status_button} onClick={() => setStatus('Авсан')}>Авсан</p> }
          { status === 'Захиалгатай' ? <p className={styles.status_button_active} onClick={() => setStatus('Захиалгатай')}>Захиалгатай</p> : <p className={styles.status_button} onClick={() => setStatus('Захиалгатай')}>Захиалгатай</p> }
          { status === 'Буцаж ирээгүй' ? <p className={styles.status_button_active} onClick={() => setStatus('Буцаж ирээгүй')}>Буцаж ирээгүй</p> : <p className={styles.status_button} onClick={() => setStatus('Буцаж ирээгүй')}>Буцаж ирээгүй</p> }
          { status === 'Өгсөн' ? <p className={styles.status_button_active} onClick={() => setStatus('Өгсөн')}>Өгсөн</p> : <p className={styles.status_button} onClick={() => setStatus('Өгсөн')}>Өгсөн</p> }
          { status === 'Зарагдсан' ? <p className={styles.status_button_active} onClick={() => setStatus('Зарагдсан')}>Зарагдсан</p> : <p className={styles.status_button} onClick={() => setStatus('Зарагдсан')}>Зарагдсан</p> }
        </div>
        {/* *********************************************************************** */}
      <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-1</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_1}
            onChange={(ev) => setCode_1(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_1}
            onChange={(ev) => setUnit_1(ev.target.value)}
          />
        </div>
        <div>
            <p>Түрээс үнэ</p>            
            <p>{parseInt(trialPrice_1) * unit_1}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>            
            <p>{parseInt(baritsaa_1) * unit_1 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_1) + (parseInt(baritsaa_1 / (unit > 3 ? 2 : 1)))) * unit_1}</p>
        </div>
        {/* <div>
          <p>{clothesCode}</p>
          <img src={`https://clothes.zyberapi.site/${photo}`}  alt="" />
        </div> */}
        <div>
          <p>Тэмдэглэл</p>
          <input 
            type="text"
            placeholder='Тэмдэглэл'
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </div>
      {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
      { post_2 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-2</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_2}
            onChange={(ev) => setCode_2(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_2}
            onChange={(ev) => setUnit_2(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_2) * unit_2}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_2) * unit_2 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_2) + (parseInt(baritsaa_2) / (unit > 3 ? 2 : 1))) * unit_2}</p>
        </div>
      </div> : <></>}
      {/* ////// */}
      { post_3 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-3</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_3}
            onChange={(ev) => setCode_3(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг-3</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_3}
            onChange={(ev) => setUnit_3(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_3) * unit_3}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_3) * unit_3 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_3) + (parseInt(baritsaa_3) / (unit > 3 ? 2 : 1))) * unit_3}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_4 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-4</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_4}
            onChange={(ev) => setCode_4(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_4}
            onChange={(ev) => setUnit_4(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_4) * unit_4}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_4) * unit_4 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_4) + (parseInt(baritsaa_4) / (unit > 3 ? 2 : 1))) * unit_4}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_5 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-5</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_5}
            onChange={(ev) => setCode_5(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_5}
            onChange={(ev) => setUnit_5(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_5) * unit_5}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_5) * unit_5 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_5) + (parseInt(baritsaa_5) / (unit > 3 ? 2 : 1))) * unit_5}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_6 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-6</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_6}
            onChange={(ev) => setCode_6(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_6}
            onChange={(ev) => setUnit_6(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_6) * unit_6}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_6) * unit_6 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_6) + (parseInt(baritsaa_6) / (unit > 3 ? 2 : 1))) * unit_6}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_7 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-7</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_7}
            onChange={(ev) => setCode_7(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_7}
            onChange={(ev) => setUnit_7(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
          <p>{parseInt(trialPrice_7) * unit_7}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>  
          <p>{parseInt(baritsaa_7) * unit_7 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_7) + (parseInt(baritsaa_7) / (unit > 3 ? 2 : 1))) * unit_7}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_8 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-8</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_8}
            onChange={(ev) => setCode_8(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_8}
            onChange={(ev) => setUnit_8(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_8) * unit_8}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_8) * unit_8 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_8) + (parseInt(baritsaa_8) / (unit > 3 ? 2 : 1))) * unit_8}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      {post_9 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-9</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_9}
            onChange={(ev) => setCode_9(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_9}
            onChange={(ev) => setUnit_9(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_9) * unit_9}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_9) * unit_9 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_9) + (parseInt(baritsaa_9) / (unit > 3 ? 2 : 1))) * unit_9}</p>
        </div>
      </div> : <></> }
      {/* ******************************************************* */}
      { post_10 === true ? <div className={styles.contact_row_create}>
        <div>
          <p>Хувцасны код-10</p>
          <input
            type="text"
            placeholder="Хувцасны код"
            value={code_10}
            onChange={(ev) => setCode_10(ev.target.value)}
          />
        </div>
        <div>
          <p>Хувцас тоо ширхэг</p>
          <input
            type="text"
            placeholder="Хувцас тоо ширхэг"
            value={unit_10}
            onChange={(ev) => setUnit_10(ev.target.value)}
          />
        </div>
        <div>
          <p>Түрээс үнэ</p>
            <p>{parseInt(trialPrice_10) * unit_10}</p>
        </div>
        <div>
          <p>Барьцаа үнэ</p>
            <p>{parseInt(baritsaa_10) * unit_10 / (unit > 3 ? 2 : 1)}</p>
        </div>
        <div>
          <p>Нийт үнэ</p>
            <p>{(parseInt(trialPrice_10) + (parseInt(baritsaa_10) / (unit > 3 ? 2 : 1))) * unit_10}</p>
        </div>
      </div> : <></> }
      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <div className={styles.contact_row_create}>
          {post_2 === false ? <button onClick={() => setPost_2(true)}>Add clothes</button> : <></>}
          {post_3 === false ? <button onClick={() => setPost_3(true)}>Add clothes</button> : <></>}
          {post_4 === false ? <button onClick={() => setPost_4(true)}>Add clothes</button> : <></>}
          {post_5 === false ? <button onClick={() => setPost_5(true)}>Add clothes</button> : <></>}
          {post_6 === false ? <button onClick={() => setPost_6(true)}>Add clothes</button> : <></>}
          {post_7 === false ? <button onClick={() => setPost_7(true)}>Add clothes</button> : <></>}
          {post_8 === false ? <button onClick={() => setPost_8(true)}>Add clothes</button> : <></>}
          {post_9 === false ? <button onClick={() => setPost_9(true)}>Add clothes</button> : <></>}
          {post_10 === false ? <button onClick={() => setPost_10(true)}>Add clothes</button> : <></>}
      </div>
      { acceptEdit === false ? <button onClick={() => setAcceptEdit(true)} className={styles.editButton}>
        Үнэ өөрчлөх 
        <FontAwesomeIcon icon={faPen} className={styles.icon} />
      </button> : <p></p>}

      { acceptEdit === true ?
        <button onClick={() => setAcceptEdit(false)} className={styles.editButton}>
        Цуцлах
        <FontAwesomeIcon icon={faPen} className={styles.icon} />
      </button> : <></>
      }
      <div className={styles.contact_row_create}>
          <div>
              <p>Нийт түрээс үнэ</p>
              <input 
                type="number"
                placeholder='Нийт түрээс үнэ'
                value={!acceptEdit ? parseInt(allTrialPrice) : parseInt(editAllTrialPrice)}
                onChange={(e) => setEditAllTrialPrice(e.target.value)}
              />
              {/* <p>{parseInt(allTrialPrice)}</p> */}
          </div>
          <div>
            <p>Нийт барьцаа үнэ</p>
            <input 
              type="number"
              placeholder='Нийт барьцаа үнэ'
              value={!acceptEdit ? (parseInt(allBaritsaa) / (unit > 3 ? 2 : 1)) 
              : (parseInt(editAllBaritsaa) ) }
              onChange={(e) => setEditAllBaritaa(e.target.value)}
            />
            {/* <p>{parseInt(allBaritsaa) / (unit > 3 ? 2 : 1)}</p> */}
          </div>
          <div>
            <p>Нийт үнэ</p>
            <input 
              type="number"
              placeholder='Нийт үнэ'
              value={!acceptEdit ? parseInt(allPrice) : parseInt(editAllPrice)}
              onChange={(e) => setEditAllPrice(e.target.value)}
            />
            {/* <p>{parseInt(allPrice)}</p> */}
          </div>
          <div>
              <p>Нийт хувцасны тоо ширхэг</p>
              <p>{unit}</p>
          </div>
        <div>
          <p>Кард</p>
          <input
            type="text"
            placeholder="Кард"
            value={card}
            onChange={(ev) => setCard(ev.target.value)}
          />
        </div>
        <div>
          <p>Бэлэн</p>
          <input
            type="text"
            placeholder="Бэлэн"
            value={belen}
            onChange={(ev) => setBelen(ev.target.value)}
          />
        </div>
        <div>
          <p>Данс</p>
          <input
            type="text"
            placeholder="Данс"
            value={dans}
            onChange={(ev) => setDans(ev.target.value)}
          />
        </div>
        <div>
          <p>Урьдчилсан захиалгын төлбөр</p>
          <input
            type="number"
            placeholder="Урьдчилсан захиалгын төлбөр"
            value={preOrderPrice}
            onChange={(ev) => setPreOrderPrice(ev.target.value)}
          />
        </div>
        <div>
          <p>Үлдэгдэл</p>
          <input
            type="number"
            placeholder="Үлдэгдэл"
            value={mainUldegdel === '' ? uldegdel : mainUldegdel}
            className={styles.uldegdel}
            onChange={(ev) => setMainUldegdel(ev.target.value)}
          />
        </div>
      </div>
      <div className={styles.contact_row_create}>
        <div>
          <p>Хувцас бүрэн өгсөн эсэх</p>
          <input
            type="text"
            placeholder="Хувцас бүрэн өгсөн эсэх"
            value={healt}
            onChange={(ev) => setHealt(ev.target.value)}
          />
        </div>
        <div>
          <p>Салбараа сонгоно уу</p>
          <div className={styles.select_container}>
            {/* <p className={styles.select_title}>Салбар</p> */}
            <select className={styles.select} value={sector} name="age" onChange={(e) => setSector(e.target.value)}>
              <option >Салбараа сонгоно уу</option>
              <option value="sectorone">Салбар-1</option>
              <option value="sectortwo">Салбар-2</option>
              <option value="sectorthree">Салбар-3</option>
            </select>
          </div>
        </div>
        <div>
          <p>Хувцас бэлдсэн</p>
          <input
            type="text"
            placeholder="Хувцас бэлдсэн"
            value={clothesPrepare}
            onChange={(ev) => setClothesPrepare(ev.target.value)}
          />
        </div>
        <div>
          <p>Захиалга бэлдсэн хүн</p>
          <input
            type="text"
            placeholder="Захиалга бэлдсэн хүн"
            value={prepareOrder}
            onChange={(ev) => setPrepareOrder(ev.target.value)}
          />
        </div>
        <div>
          <p>Захиалга авсан хүн</p>
          <input
            type="text"
            placeholder="Захиалга авсан хүн"
            value={preOrder}
            onChange={(ev) => setPreOrder(ev.target.value)}
          />
        </div>
        <div>
          <p>Захиалга өгсөн хүн</p>
          <input
            type="text"
            placeholder="Захиалга өгсөн хүн"
            value={ordered}
            onChange={(ev) => setOrdered(ev.target.value)}
          />
        </div>
        <div>
          <p>Хүлээн авсан хүн</p>
          <input
            type="text"
            placeholder="Хүлээн авсан хүн"
            value={acceptOrder}
            onChange={(ev) => setAcceptOrder(ev.target.value)}
          />
        </div>
      </div>
      {/* <input type="file" onChange={(ev) => setFiles(ev.target.files)} /> */}
      {/* <Editor value={content} onChange={setContent} /> */}
      <button className={styles.doneButton} style={{ marginTop: "5px" }}>
        {/* <FontAwesomeIcon icon={faCheck} className={styles.icon} /> */}
        Хэрэглэгчийн бүртгэлийг нэмэх
      </button>
      <button className={styles.cancelButton} onClick={() => setPost(false)} style={{ marginTop: "5px" }}>
        {/* <FontAwesomeIcon onClick={() => (setPost(false))} icon={faXmark} className={styles.icon} /> */}
        Хэрэглэгчийн бүртгэлийг цуцлах
      </button>
     </form> : 
     <>
      <h2>Loading {clothes.length}</h2>
     </> : <button className={styles.createButton} onClick={() => setPost(true)}>Шинээр бүртгэх</button>}
   </div>
  )
}

export default CreateList
